<template>
    <section>
        <div class="row mx-3 f-18 text-orange f-600 mt-3 bg-white br-8 py-3">
            <div class="col text-general">
                Cuentas por cobrar en los próximos 2 meses
            </div>
        </div>
        <graficaMeses />
        <div v-loading="loading" class="row mx-3 py-3 bg-white br-8 mt-4 justify-center">
            <div class="col mx-1 border-r-gris py-2 text-general">
                <p class="text-center f-15">
                    Deuda vencida
                </p>
                <p class="text-center f-18 f-600">
                    {{ separadorNumero(info.deuda_vencidas) }}
                </p>
            </div>
            <div class="col px-1 border-r-gris py-2 mx-1 text-general">
                <p class="text-center f-15">
                    Deuda total
                </p>
                <p class="text-center f-18 f-600">
                    {{ separadorNumero(info.deuda_total) }}
                </p>
            </div>
            <div class="col mx-1 border-r-gris py-2 text-general">
                <p class="text-center f-15">
                    Deuda por cobrar
                </p>
                <p class="text-center f-18 f-600">
                    {{ separadorNumero(info.deuda_por_cobrar) }}
                </p>
            </div>
            <div class="col mx-1 border-r-gris py-2 text-general">
                <p class="text-center f-15">
                    Cuotas Vencidas
                </p>
                <p class="text-center f-18 f-600">
                    {{ info.cuotas_vencidas }}
                </p>
            </div>
            <div class="col mx-1  py-2 text-general">
                <p class="text-center f-15">
                    Próximas Cuotas
                </p>
                <p class="text-center f-18 f-600">
                    {{ info.proxi_cuotas }}
                </p>
            </div>
        </div>
        <div class="row mx-0 f-18 text-general mt-4">
            <div class="col">
                <tabla-general v-loading="loading" :data="tableData" :columnas="dataColumnas">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-600">
                            Cuentas pendientes
                        </div>
                    </template>
                    <template v-slot:adicionales-izquierda>
                        <el-table-column prop="propietario_nombre" :label="`${$config.vendedor}`" sortable min-width="200" class-name="text-general f-600">
                            <template slot-scope="scope">
                                <!-- <router-link :to="" class="text-general" style="text-decoration:none;"> -->
                                <p class="cr-pointer text-general2">
                                    {{ scope.row.propietario_nombre }}
                                </p>
                                <!-- </router-link> -->
                            </template>
                        </el-table-column>
                        <el-table-column prop="id_pedido_admin" label="Pedido" sortable width="100" class-name="text-center text-general">
                            <template slot-scope="scope">
                                <p class="text-general2">
                                    {{ scope.row.id_pedido_admin }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="valor_final" label="Valor del pedido" sortable min-width="180" class-name="text-center text-general">
                            <template slot-scope="scope">
                                <p class="text-general2">
                                    {{ separadorNumero(scope.row.valor_final) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at_pedido" label="Fecha del pedido" sortable width="200" class-name="text-center text-general">
                            <template slot-scope="scope">
                                <p class="text-general2">{{ formatearFecha(scope.row.created_at_pedido,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:adicionales-derecha>
                        <el-table-column prop="valor_pendiente" label="Valor pendiente" sortable min-width="150" class-name="text-center text-general">
                            <template slot-scope="scope">
                                <p class="text-general2">
                                    {{ separadorNumero(scope.row.valor_pendiente) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fecha" label="Fecha Cuota" sortable width="120" class-name="text-general">
                            <template slot-scope="scope">
                                <p :class="scope.row.vencido?'text-danger-fecha': ''">{{ formatearFecha(scope.row.fecha,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="$can('boton_tesoreria_cuentas_registrar_pago')" width="180">
                            <template slot-scope="scope">
                                <div class="bg-general3 br-12 px-2 py-1 text-white text-center cr-pointer" @click="AbrirResgistrarPago(scope.row)">
                                    Registrar pago
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Modales -->
        <modalRegistrarPagos ref="RegistrarPago" @registro="realizoPago" />
    </section>
</template>

<script>
import Creditos from '~/services/almacen/creditos'
import {mapGetters} from 'vuex'


export default {
    components: {
        modalRegistrarPagos: () => import('./partials/modalRegistrarPago'),
        graficaMeses: () => import('./components/graficaMeses')

    },
    data(){
        return{
            loading: false,
            dataColumnas: [
                'propietario_nombre', 'id_pedido_admin','valor_final','valor_pendiente','fecha','created_at_pedido'
            ],
            // info:{
            //     cuotas_vencidas: 0,
            //     deuda_por_cobrar: 0,
            //     deuda_vencidas: 0,
            //     proxi_cuotas: 0,
            //     deuda_total: 0
            // },
            /* tableData: [
                { nombre: 'OXXO', deuda: '$ 534.000', valor_pedido: '01 Jul. 2018', fecha_pedido: '01 Jul. 2018', proximo_pago: '05 Jul. 2008' },
            ], */
        }
    },
    computed:{
        ...mapGetters({
            info: 'almacen/credito/infoTabCuotas',
            tableData: 'almacen/credito/tablaCuotas',
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch:{
        id_cedis(val, oldVal){
            if(oldVal == null) return
            this.listarCuotas()
        },
        id_moneda(val, oldVal){
            if(oldVal == null) return
            this.listarCuotas()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        AbrirResgistrarPago(row){
            this.$router.push({name: 'admin.tesoreria.creditos.cobro.ver', params: { id_pedido: row.id_pedido_admin }})
        },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.listarCuotas()
                        await this.listarDatosGraficas()
                    }, 1000);
                }else{
                    await this.listarCuotas()
                    await this.listarDatosGraficas()
                }
            } catch (error){
                console.log(error)
            }
        },
        realizoPago(pago){
            this.listarCuotas()
            this.listarDatosGraficas()
        },
        async listarCuotas(){
            try {
                const params = {
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                    tendero: 1
                }
                this.loading = true
                await this.$store.dispatch('almacen/credito/action_listar_cuotas', params)
                this.loading = false
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        listarDatosGraficas(){
            this.$store.commit('almacen/credito/set_grafica_dos_meses', [])
            const params = {
                id_cedis: this.id_cedis,
                id_moneda: this.id_moneda,
                tendero: 1
            }
            this.$store.dispatch('almacen/credito/action_listar_creditos_grafica_dos_meses', params)
        }
    }
}
</script>

<style lang="scss" scoped>
.box-shadow-29{
    box-shadow: 0px 3px 6px #00000029;
}
.br-12{
    border-radius: 12px;
}
.text-danger-fecha{
    color: #FF0000;
}
</style>
